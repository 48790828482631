import React from 'react'
import { Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
const NewsItems =(props) => {
  let {title,id, description,category, imageUrl,newsUrl,auther,updateDate,source,urlToImage} = props;
  const handleShare = async (title,category) => {
    try {
        if (navigator.share) {
            await navigator.share({
                title: title,
                url: `/news?title=${id}&category=${category}`
            });
            console.log('Content shared successfully.');
        } else {
            console.log('Web Share API not supported.');
        }
    } catch (error) {
        console.error('Error sharing content:', error);
    }
};
  return (
    <><div key={`news_item_${id}`} >
            <div key={`news_item1_${id}`} className="card" >
            <img key={`news_item2_${id}`}  src={imageUrl} className="card-img-top" alt="/" / >
            <div className="card-body" key={`news_item3_${id}`} >
                <h5 className="card-title" key={`news_item4_${id}`} >
                <Link key={`news_item10_${id}`} 
                    className="nav-link"
                    to={`/news?title=${id}&category=${category}`}
                    >{title}
                </Link>
                <span key={`news_item5_${id}`}  className='position-absolute top-0 translate-middle badge bg-danger rounded-pill' style={{left:'90%', zIndex:'1'}}>{source}</span></h5>
                <p key={`news_item6_${id}`}  className="card-text">{description}</p>
                <p key={`news_item7_${id}`}  className="card-text"><small className='text-muted'>By {auther} on {updateDate}</small></p>
                <a key={`news_item8_${id}`}  href={newsUrl} target='_blank' rel="noopener noreferrer" className="btn btn-sm btn-primary">Read More</a>
                <button key={`news_item9_${id}`}  onClick={()=>handleShare(title,category)} className="btn btn-sm btn-secondary ms-2">Share</button>
            </div>
            </div>
      </div>
      </>
    )
}
export default NewsItems