import react, { useState } from "react";
import NewsContext from "./newsContext";

const NewsState = (props) => {
  const host = 'http://localhost:5002';
  
    const newsInitial = [];
    const[newses, setNewses] = useState(newsInitial);
    
    //Add a news
    const getAllNews = async () =>{
      const response = await fetch(`${host}/api/news/fetchallnews`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          "auth-token" :"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ4OThmYTUwMjRkNjAwOGE2ZGNkNTVmIn0sImlhdCI6MTY4NzE1NjM5OH0.8giI5bLDtQjeR4oIeQuSN9iZR-a7F5Rc7FKJnazSIY4"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(), // body data type must match "Content-Type" header
      });
      const json = await response.json();
      console.log(json);
      setNewses(json);
    }
  //Add a news 
  const addNews = async (news) =>{
    news = {
      "newsId":1,
      "title": news.title,
      "country": news.country,
      "category": news.category,
      "description": news.description,
      "imageUrl":news.imageUrl,
      "newsUrl":news.newsUrl,
      "auther":news.auther,
      "updateDate":news.updateDate,
      "source":news.source,
      "urlToImage":news.urlToImage,
      "status":1
    }
    setNewses(newses.concat(news));

    const response = await fetch(`${host}/api/news/addnews`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        "auth-token" :"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ4OThmYTUwMjRkNjAwOGE2ZGNkNTVmIn0sImlhdCI6MTY4NzE1NjM5OH0.8giI5bLDtQjeR4oIeQuSN9iZR-a7F5Rc7FKJnazSIY4"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(news), // body data type must match "Content-Type" header
    });
    console.log('responseresponse',response)
  }
  return (
    <NewsContext.Provider value={{newses,addNews,getAllNews}}>
        {props.children}
    </NewsContext.Provider>
  )
}
export default NewsState;