import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet';

const NewsItemsDetail = (props) => {
  const [queryData, setQueryData] = useState({});

  const fetchNewsData = async () => {
    //props.setProgress(0);
    var abcT = '';
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    for (const [key, value] of queryParams.entries()) {
      if (key === 'title') {
        abcT = value;
      }
    }
    //let url = 'http://localhost/test/getnews.php';
    let url = 'https://news.g1tech.in/getnews.php';
    let postData = {
      title: abcT,
      isSingle: 1
    };

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    };
    try {
      let newsData = await fetch(url, options);
      let parsedData = await newsData.json();
      const queryParams = parsedData[0];
      console.log('parsedData', queryParams);
      setQueryData(queryParams);
      document.title = queryParams.category + " - G1News";
      //props.setProgress(100);
    } catch (error) {
      console.error('Error fetching news data:', error);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          url: window.location.href
        });
      } else {
        console.log('Web Share API not supported.');
      }
    } catch (error) {
      console.error('Error sharing content:', error);
    }
  };

  useEffect(() => {
    document.title = props.category + " - G1News";
    fetchNewsData();
  }, []);

  let { title, description, imageUrl, newsUrl, auther, updateDate, source } = queryData;
  return (
    <>
      {/* <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet> */}
      <Card className='container'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <CardMedia
              component="img"
              alt="/"
              height="100%"
              src={imageUrl}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <CardContent>
              <Typography variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                By {auther} on {updateDate} | Source: {source}
              </Typography>
              <Button
                href={newsUrl}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                size="small"
              >
                Read More
              </Button>
              <Button
                onClick={handleShare} // Change to Button from MUI
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: '8px' }} // Adjust spacing
              >
                Share
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default NewsItemsDetail;
