import React, { useEffect, useState } from "react";
import NewsItems from './NewsItems';
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from 'react-helmet';
const News = (props) => {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [pageSize, setPageSize] = useState(6);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const updateNews = async () => {
        //let url = 'http://localhost/test/getnews.php';
        let url = 'https://news.g1tech.in/getnews.php';
        let data = {
            country: props.country,
            category: props.category,
            apiKey: props.apiKey,
            page: page,
            pageSize: 6
        };

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        try {
            let newsData = await fetch(url, options);
            let parsedData = await newsData.json();
            setArticles(articles.concat(parsedData));
            setTotalResults(parsedData.totalResults);
            props.setProgress(100);
        } catch (error) {
            console.error('Error fetching news data:', error);
        }
    }

    useEffect(() => {
        document.title = Capitalize(props.category) + " - G1News";
        updateNews();
    }, []);

    const fetchMoreData = async () => {
        setPage(page + 1)
        await updateNews();
    }

    return (
        <>{/* <Helmet>
                <title>G1 News : Daily News</title>
                <meta name="description" content="G1News is a news app which can be used to grab quick daily news bites. If you are interested in news, wether, politics and sports news, G1News for you." />
            </Helmet> */}
            <h2 className='text-center' style={{ margin: '10px' }}>Top Headlines - {Capitalize(props.category)}</h2>
            <div className='text-center'>
                {/*this.state.loading && <Spinner />*/}
            </div>
            <InfiniteScroll
                dataLength={articles.length}
                next={fetchMoreData}
                hasMore={totalResults !== articles.length}
                loader={<Spinner />}
            >
                <div className="container">
                    <div className="row">
                    {articles.map((element, index) => {
                        return (
                            <div className="col-md-4" key={element.url}>
                                <NewsItems
                                    key={`news_${element.id}`} 
                                    category={element.category} 
                                    title={element.title} 
                                    description={element.description} 
                                    imageUrl={!element.urlToImage ? "https://cdn.ndtv.com/common/images/ogndtv.png" : element.urlToImage} 
                                    newsUrl={element.url} 
                                    updateDate={element.publishedAt} 
                                    auther={element.auther} 
                                    source={element.source} 
                                    id={element.id} 
                                />
                            </div>
                        );
                    })}

                    </div>
                </div>
            </InfiniteScroll>
        </>
    )
}

News.defaultProps = {
    country: 'in',
    category: 'sport',
    pageSize: 6
}

News.propTypes = {
    country: PropTypes.string,
    category: PropTypes.string,
    pageSize: PropTypes.number,
}

export default News;
