import './App.css';
import React, { useState } from 'react'
import NavBar from './components/NavBar';
import News from './components/News';
import {BrowserRouter as Router,Routes,
  Route
} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'
import NewsState from './context/news/NewsState';
import NewsItemsDetail from './components/NewsItemsDetail';
import { Helmet } from 'react-helmet';

const App = ()=> {
  const apiKey = process.env.REACT_APP_NEWS_API;
  const [progress,setProgress] = useState(0);
  const setProgressFun = (progress) =>{
    setProgress(progress);
  }
    return (
      <>{/* <Helmet>
      <title>G1 News : Daily News</title>
      <meta name="description" content="G1News is a news app which can be used to grab quick daily news bites. If you are interested in news, wether, politics and sports news, G1News for you." />
  </Helmet> */}
      <NewsState>
      <Router>
      <div>
        <NavBar />
        <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
        <Routes>
          <Route exact  path='/' element={<News setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="business" country="in" key="business" />}></Route>
          <Route exact  path='/business' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="business" country="in" key="business1" />}></Route>
          <Route exact  path='/entertainment' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="entertainment" country="in" key="entertainment" />}></Route>
          <Route exact  path='/general' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="general" country="in" key="general" />}></Route>
          <Route exact  path='/health' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="health" country="in" key="health" />}></Route>
          <Route exact  path='/science' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="science" country="in" key="science" />}></Route>
          <Route exact  path='/sport' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="sport" country="in" key="sport" />}></Route>
          <Route exact  path='/technology' element={<News  setProgress={setProgressFun} apiKey={apiKey} pageSize={6} category="technology" country="in" key="technology" />}></Route>
          <Route path='/news' element={<NewsItemsDetail  setProgress={setProgressFun} apiKey={apiKey} key="technology1" category="technology" country="in"  />}></Route>
          <Route path='/khabar' element={<NewsItemsDetail  setProgress={setProgressFun} apiKey={apiKey} key="technology1" category="technology" country="in"  />}></Route>
        </Routes>
      </div>
      </Router>
      </NewsState>
      </>
    )
}


export default App;
